import { useState, useEffect, useRef, SetStateAction } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { ALERTAS } from '../utilities/constantes';
import { useForm } from 'react-hook-form';
import { SCCalendar, SCCheckbox, SCDropdown, SCFieldset, SCInputMask, SCInputText } from '../components';
import AjudaFormTela from './AjudaFormTela';
import { CheckboxChangeParams } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';


const AjustaDiarioFinanceiro = () => {
  const toast = useToast();
  const { periodoSelecionado, usuario } = useAuth();
  const tarefa = 'AjustaInformacaoDiarioFinanceiro';

  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const [diarioFinanceiro, setDiarioFinanceiro] = useState<any>([]);
  const [usuarios, setUsuario] = useState<any>([]);
  const [formasPagamento, setFormasPagamento] = useState<any>([]);
  const [fonte, setFonte] = useState<any>([]);
  const [tarefas, setTarefas] = useState<any>([]);
  const [nome, setNome] = useState<any>([]);
  const [arrayIds, setArrayIds] = useState<any[]>([]);

  const methods = useForm();
  const { control, formState: { errors }, watch, getValues, setValue } = methods;

  const watchUsuario = watch('LancamentoOperador')
  const watchFormasPagamento = watch('FormasPagamentoId')
  const watchFonte = watch('FonteId')
  const watchTarefa = watch('Tarefa')
  const watchOpcao = watch('Opcao')
  const watchNome = watch('Nome')
  let watchDataLancInicial = watch('PeriodoLancamentoInicial');
  let watchDataLancFinal = watch('PeriodoLancamentoFinal');
  let watchDataPagInicial = watch('PeriodoPagamentoInicial');
  let watchDataPagFinal = watch('PeriodoPagamentoFinal');

  const pesquisa = async () => {

    const lancamentoInicial = watchDataLancInicial ? new Date(watchDataLancInicial) : null;
    const lancamentoFinal = watchDataLancFinal ? new Date(watchDataLancFinal) : null;
    if (lancamentoInicial && !lancamentoFinal) {
      watchDataLancFinal = new Date('3000-12-31');
    }
    if (!lancamentoInicial && lancamentoFinal) {
      watchDataLancInicial = new Date('1000-12-31');
    }

    const pagamentoInicial = watchDataPagInicial ? new Date(watchDataPagInicial) : null;
    const pagamentoFinal = watchDataPagFinal ? new Date(watchDataPagFinal) : null;
    if (pagamentoInicial && !pagamentoFinal) {
      watchDataPagFinal = new Date('3000-12-31');
    }
    if (!pagamentoInicial && pagamentoFinal) {
      watchDataPagInicial = new Date('1000-12-31');
    }

    const formatedDataLancInicial = watchDataLancInicial ? new Date(watchDataLancInicial).toISOString().split('T')[0] : null;
    const formatedDataLancFinal = watchDataLancFinal ? new Date(watchDataLancFinal).toISOString().split('T')[0] : null;
    const formatedDataPagInicial = watchDataPagInicial ? new Date(watchDataPagInicial).toISOString().split('T')[0] : null;
    const formatedDataPagFinal = watchDataPagFinal ? new Date(watchDataPagFinal).toISOString().split('T')[0] : null;

    const result = await api.get('/diariofinanceiro/listDiarioFinanceiro', {
      params:
      {
        EmpresaId: periodoSelecionado?.EmpresaId,
        Ano: periodoSelecionado?.Ano,
        Sequencial: periodoSelecionado?.Sequencial,
        DataLancInicial: formatedDataLancInicial,
        DataLancFinal: formatedDataLancFinal,
        LancamentoOperador: watchUsuario,
        DataPagInicial: formatedDataPagInicial,
        DataPagFinal: formatedDataPagFinal,
        Nome: getValues('Nome'),
        ParcelaInicial: getValues('ParcelaInicial'),
        ParcelaFinal: getValues('ParcelaFinal'),
        FonteId: watchFonte,
        FormasPagamentoId: watchFormasPagamento,
        Tarefa: watchTarefa,
        Opcao: watchOpcao
      }
    });

    if (result.data) {
      console.log('DADOS RECEBIDOS', result.data)
      setDiarioFinanceiro(result.data)
    } else {
      setDiarioFinanceiro([])
    }
  };

  const load = async () => {
    const resultOperador = await api.get('/diariofinanceiro/listColunasDiarioFinanceiro', {
      params: {
        EmpresaId: periodoSelecionado?.EmpresaId,
        Ano: periodoSelecionado?.Ano,
        Sequencial: periodoSelecionado?.Sequencial,
        ShowLancamentoOperador: true
      }
    })
    if (resultOperador.data.length > 0) {
      setUsuario(resultOperador.data)
    } else {
      setUsuario([])
    }

    const resultFormasPagamento = await api.get('/formasPagamento/list')
    if (resultFormasPagamento.data.length > 0) {
      setFormasPagamento(resultFormasPagamento.data)
    } else {
      setFormasPagamento([])
    }

    const resultFontes = await api.get('/fontes/list', {
      params: {
        EmpresaId: periodoSelecionado?.EmpresaId
      }
    })
    if (resultFontes.data.length > 0) {
      setFonte(resultFontes.data)
    } else {
      setFonte([])
    }

    const resultTarefas = await api.get('/diariofinanceiro/listColunasDiarioFinanceiro', {
      params: {
        EmpresaId: periodoSelecionado?.EmpresaId,
        Ano: periodoSelecionado?.Ano,
        Sequencial: periodoSelecionado?.Sequencial,
        ShowTarefa: true
      }
    })
    if (resultTarefas.data.length > 0) {
      setTarefas(resultTarefas.data)
    } else {
      setTarefas([])
    }

    const resultNome = await api.get('/diariofinanceiro/listColunasDiarioFinanceiro', {
      params: {
        EmpresaId: periodoSelecionado?.EmpresaId,
        Ano: periodoSelecionado?.Ano,
        Sequencial: periodoSelecionado?.Sequencial,
        ShowNome: true
      }
    })
    if (resultNome.data.length > 0) {
      setNome(resultNome.data)
    } else {
      setNome([])
    }
  };

  useEffect(() => {
    load();
  }, []);

  const onSubmit = async () => {
    try {
      const promises: any[] = [];
      const idsSet = new Set(arrayIds);
      diarioFinanceiro.forEach(async (item: any) => {
        if (idsSet.has(item.DiarioFinanceiroId)) {

          let planoValor;
          if (item.PlanoValor) {
            const resultConta = await api.get('/planoContas', {
              params: {
                Conta: item.PlanoValor
              }
            })
            if (resultConta.data.length > 0) {
              planoValor = resultConta.data[0].PlanoContaId;
            } else {
              planoValor = null;
            }
          }

          let planoDesconto;
          if (item.PlanoDesconto) {
            const resultConta = await api.get('/planoContas', {
              params: {
                Conta: item.PlanoDesconto
              }
            })
            if (resultConta.data.length > 0) {
              planoDesconto = resultConta.data[0].PlanoContaId;
            } else {
              planoDesconto = null;
            }
          }

          let planoJuros;
          if (item.PlanoJuros) {
            const resultConta = await api.get('/planoContas', {
              params: {
                Conta: item.PlanoJuros
              }
            })
            if (resultConta.data.length > 0) {
              planoJuros = resultConta.data[0].PlanoContaId;
            } else {
              planoJuros = null;
            }
          }

          let planoMulta;
          if (item.PlanoMulta) {
            const resultConta = await api.get('/planoContas', {
              params: {
                Conta: item.PlanoMulta
              }
            })
            if (resultConta.data.length > 0) {
              planoMulta = resultConta.data[0].PlanoContaId;
            } else {
              planoMulta = null;
            }
          }

          const [diaL, mesL, anoL] = item.LancamentoDataString ? item.LancamentoDataString.split('/') : undefined;
          const [diaP, mesP, anoP] = item.DataPagamentoString ? item.DataPagamentoString.split('/') : undefined;
          const [diaD, mesD, anoD] = item.DataDisponivelString ? item.DataDisponivelString.split('/') : undefined;
          const [diaB, mesB, anoB] = item.DataBomParaString ? item.DataBomParaString.split('/') : undefined;

          const dataLancFormatada = anoL ? `${anoL}-${mesL}-${diaL}` : null;
          const dataPagFormatada = anoP ? `${anoP}-${mesP}-${diaP}` : null;
          const dataDispFormatada = anoD ? `${anoD}-${mesD}-${diaD}` : null;
          const dataBomParaFormatada = anoB ? `${anoB}-${mesB}-${diaB}` : null;

          if (item.Tarefa === 'APAGAR') {
            const arraySalvarApagar = {
              //usado em Apagar
              ApagarId: item.NumeroRecibo,
              Referente: item.Referencia,
              Valor: item.Valor,
              DataPagamento: dataPagFormatada,
              LancamentoData: dataLancFormatada,
              DataBomPara: dataBomParaFormatada,
              ContaMultaPagamento: planoMulta,
              ContaJurosPagamento: planoJuros,

              //usado somente em DiarioFinanceiro
              DiarioFinanceiroId: item.DiarioFinanceiroId,
              Tarefa: item.Tarefa,
              DataDisponivel: dataDispFormatada,
              Referencia: item.Referencia,
              ContaValorId: planoValor,
              ContaDescontoId: planoDesconto,
              ContaMultaId: planoMulta,
              ContaJurosId: planoJuros,
            }
            promises.push(api.put('/diariofinanceiro', { Diarios: arraySalvarApagar }));

          } else if (item.Tarefa === 'OUTPAG') {
            const arraySalvarOutpag = {
              //usado em OutrosRecebimentos
              OutrosRecebimentosId: item.NumeroRecibo,
              Valor: item.Valor,
              DataPagamento: dataPagFormatada,
              DataDisponivel: dataDispFormatada,
              Referencia: item.Referencia,
              DataBomPara: dataBomParaFormatada,
              PlanoContaId: planoValor,

              //usado somente em DiarioFinanceiro
              DiarioFinanceiroId: item.DiarioFinanceiroId,
              LancamentoData: dataLancFormatada,
              Tarefa: item.Tarefa,
              ContaValorId: planoValor,
              ContaDescontoId: planoDesconto,
              ContaMultaId: planoMulta,
              ContaJurosId: planoJuros,
            }
            promises.push(await api.put('/diariofinanceiro', { Diarios: arraySalvarOutpag }));

          }
        }
      })
      await Promise.all(promises);
      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      load();

    } catch (e: any) {
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }
  };

  const onEditorValueChange = (props: any, value: any) => {
    let updatedDiarioFinanceiro = [...diarioFinanceiro];
    updatedDiarioFinanceiro[props.rowIndex][props.field] = value;
    setDiarioFinanceiro(updatedDiarioFinanceiro);
  };

  const inputDateEditor = (props: any) => {
    const field = props.field;
    const rowIndex = props.rowIndex;

    const handleBlur = (e: any) => {
      const value = e.target.value;
      onEditorValueChange({ field, rowIndex }, value);
      const id = props.rowData.DiarioFinanceiroId;

      if (id && !arrayIds.includes(id)) {
        setArrayIds(prevArrayIds => [...prevArrayIds, id]);
      }
    };

    const handleControl = (e: any) => {
      const charCode = e.charCode;
      const newValue = e.target.value + String.fromCharCode(charCode);
    };

    return (
      <SCInputMask
        name=''
        mask='99/99/9999'
        maxLength={2}
        value={props.rowData[props.field]}
        onBlur={handleBlur}
        onChange={handleControl}
        style={{ maxWidth: '60%' }}
      />
    );
  };

  const inputTextEditor = (props: any) => {
    const field = props.field;
    const rowIndex = props.rowIndex;

    const handleBlur = (e: any) => {
      const value = e.target.value;
      onEditorValueChange({ field, rowIndex }, value);
      const id = props.rowData.DiarioFinanceiroId;

      if (id && !arrayIds.includes(id)) {
        setArrayIds(prevArrayIds => [...prevArrayIds, id]);
      }
    };

    const handleControl = (e: any) => {
      const charCode = e.charCode;
    };

    return (
      <InputText
        type="text"
        defaultValue={props.rowData[props.field]}
        onBlur={handleBlur}
        onKeyPress={handleControl}
        style={{ maxWidth: '100%' }}
      />
    );
  };

  const inputValueEditor = (props: any) => {
    const field = props.field;
    const rowIndex = props.rowIndex;

    const handleBlur = (e: any) => {
      const value = e.target.value;
      onEditorValueChange({ field, rowIndex }, value);
      const id = props.rowData.DiarioFinanceiroId;

      if (id && !arrayIds.includes(id)) {
        setArrayIds(prevArrayIds => [...prevArrayIds, id]);
      }
    };

    const handleControl = (e: any) => {
      const charCode = e.charCode;
    };

    return (
      <InputText
        type="number"
        defaultValue={props.rowData[props.field]}
        onBlur={handleBlur}
        onKeyPress={handleControl}
        style={{ maxWidth: '40%' }}
      />
    );
  };

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Ajusta Informação do Diário Financeiro</h5>
            <AjudaFormTela tarefa={tarefa} campo='' />
          </div>
          <SCFieldset legend='Filtros' className='col-12 mt-2 pt-0'>
            <div className='grid'>
              <div className="col-12 md:col-3">
                <SCCalendar
                  control={control}
                  label='Período Lançamento Inicial'
                  name='PeriodoLancamentoInicial'
                  dateFormat='dd/mm/yy'
                  mask='99/99/9999'
                  placeholder='Data Inicial'
                />
              </div>
              <div className="col-12 md:col-3">
                <SCCalendar
                  control={control}
                  label='Período Lançamento Final'
                  name='PeriodoLancamentoFinal'
                  dateFormat='dd/mm/yy'
                  mask='99/99/9999'
                  placeholder='Data Final'
                />
              </div>
            </div>

            <div className='grid'>
              <div className="col-12 md:col-3">
                <SCCalendar
                  control={control}
                  label='Período de Pagamento Inicial'
                  name='PeriodoPagamentoInicial'
                  dateFormat='dd/mm/yy'
                  mask='99/99/9999'
                  placeholder='Data Inicial'
                />
              </div>

              <div className="col-12 md:col-3">
                <SCCalendar
                  control={control}
                  label='Período de Pagamento Final'
                  name='PeriodoPagamentoFinal'
                  dateFormat='dd/mm/yy'
                  mask='99/99/9999'
                  placeholder='Data Final'
                />
              </div>

            </div>
            <div className='grid'>
              <div className="col-12 md:col-3">
                <SCDropdown
                  name="Tarefa"
                  label='Tarefa'
                  options={tarefas}
                  optionLabel='TarefaString'
                  optionValue='Tarefa'
                  value={watchTarefa}
                  onChange={(e) => setValue('Tarefa', e.target.value)}
                  style={{ width: '100%' }}
                />
              </div>
              {/* <div className="col-12 md:col-3">
                                <SCDropdown
                                    name="Opcao"
                                    label='Opção'
                                    options={opcao}
                                    optionLabel='OpcaoString'
                                    optionValue='Opcao'
                                    value={watchOpcao}
                                    onChange={(e) => setValue('Opcao', e.target.value)}
                                    style={{ width: '100%' }}
                                />
                            </div> */}
              {/* <div className="col-12 md:col-3">
                                <SCInputText
                                    control={control}
                                    errors={errors}
                                    name="Valor"
                                    label='Valor'
                                    style={{ width: '100%' }}
                                />
                            </div> */}
              <div className="col-12 md:col-3">
                <SCDropdown
                  name="FonteId"
                  label='Fonte'
                  options={fonte}
                  optionLabel='Codigo'
                  optionValue='FonteId'
                  value={watchFonte}
                  onChange={(e) => setValue('FonteId', e.target.value)}
                  style={{ width: '100%' }}
                />
              </div>
              <div className="col-12 md:col-3">
                <SCDropdown
                  name="FormasPagamentoId"
                  label='Forma de Pagamento'
                  options={formasPagamento}
                  optionLabel='Descricao'
                  optionValue='FormasPagamentoId'
                  value={watchFormasPagamento}
                  onChange={(e) => setValue('FormasPagamentoId', e.target.value)}
                  style={{ width: '100%' }}
                />
              </div>

              {/* <div className="col-12 md:col-3">
                                <SCInputText
                                    control={control}
                                    name="ParcelaInicial"
                                    label='Parcela Inicial'
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <div className="col-12 md:col-3">
                                <SCInputText
                                    control={control}
                                    name="ParcelaFinal"
                                    label='Parcela Final'
                                    style={{ width: '100%' }}
                                />
                            </div> */}
              <div className="col-12 md:col-3">
                <SCDropdown
                  name="LancamentoOperador"
                  label='Operador'
                  options={usuarios}
                  optionLabel='LancamentoOperador'
                  optionValue='LancamentoOperador'
                  value={watchUsuario}
                  onChange={(e) => setValue('LancamentoOperador', e.target.value)}
                  style={{ width: '100%' }}
                />
              </div>
              <div className="col-12 md:col-6">
                <SCDropdown
                  name="Nome"
                  label='Nome'
                  options={nome}
                  optionLabel='Nome'
                  optionValue='Nome'
                  value={watchNome}
                  onChange={(e) => setValue('Nome', e.target.value)}
                  style={{ width: '100%' }}
                />
              </div>
              <div className='col-12 md:col-2 flex align-items-end'>
                <Button label='Pesquisar' icon="pi pi-search" className="p-button-info" type='button' onClick={pesquisa} />
              </div>
            </div>
          </SCFieldset>
          <DataTable
            size='small'
            stripedRows
            value={diarioFinanceiro}
            dataKey="DiarioFinanceiroId"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} a {last} de {totalRecords}"
            globalFilter={globalFilter}
            emptyMessage="Nenhum registro encontrado."
            header={null}
            scrollable
            scrollHeight="800px"
            responsiveLayout="scroll">
            <Column field="LancamentoDataString" header="Data Lançamento" headerStyle={{ width: '200px', background: '#19bfaf' }} sortable editor={inputDateEditor} ></Column>
            <Column field="DataPagamentoString" header="Data Pagamento" headerStyle={{ width: '200px', background: '#19bfaf' }} sortable editor={inputDateEditor} />
            <Column field="DataDisponivelString" header="Data Disponível" headerStyle={{ width: '200px', background: '#19bfaf' }} sortable editor={inputDateEditor} ></Column>
            <Column field="DataBomParaString" header="Data 'Bom Para'" headerStyle={{ width: '200px', background: '#19bfaf' }} sortable editor={inputDateEditor} ></Column>
            <Column field="LancamentoOperador" header="Operador" headerStyle={{ width: '200px' }} ></Column>
            <Column field="Codigo" header="Fonte" headerStyle={{ width: '200px' }} ></Column>
            <Column field="Descricao" header="Forma de Pagamento" headerStyle={{ width: '200px' }} ></Column>
            <Column field="Tarefa" header="Tarefa" headerStyle={{ width: '200px' }} ></Column>
            <Column field="OpcaoString" header="Opção" headerStyle={{ width: '200px' }} ></Column>
            <Column field="Nome" header="Nome" headerStyle={{ width: '200px' }} ></Column>
            <Column field="Referencia" header="Referencia" headerStyle={{ width: '350px', background: '#19bfaf' }} editor={inputTextEditor} ></Column>
            <Column field="Valor" header="Valor" align='center' headerStyle={{ width: '80px' }}
              // editor={inputValueEditor}
              body={(rowData: any) => {
                const valor = Number(rowData.Valor);
                return (
                  <span>R$ {isNaN(valor) ? '0,00' : valor.toFixed(2).replace('.', ',')}</span>
                );
              }} />
            <Column field="NumeroRecibo" headerStyle={{ width: '80px' }} header="Recibo" ></Column>
            <Column field="Matricula" headerStyle={{ width: '100px' }} header="Matrícula" ></Column>
            <Column field="Parcela" headerStyle={{ width: '200px' }} header="Parcela" ></Column>
            <Column field="" headerStyle={{ width: '200px' }} header="Número Documento" ></Column>
            <Column field="PlanoValor" header="Conta Valor" headerStyle={{ background: '#19bfaf', width: '200px' }} editor={inputValueEditor} body={(rowData) => rowData.PlanoValor || ''} />
            <Column field="PlanoDesconto" header="Conta Desconto" headerStyle={{ background: '#19bfaf', width: '200px' }} editor={inputValueEditor} body={(rowData) => rowData.PlanoDesconto || ''} />
            <Column field="PlanoMulta" header="Conta Multa" headerStyle={{ background: '#19bfaf', width: '200px' }} editor={inputValueEditor} body={(rowData) => rowData.PlanoMulta || ''} />
            <Column field="PlanoJuros" header="Conta Juros" headerStyle={{ background: '#19bfaf', width: '200px' }} editor={inputValueEditor} body={(rowData) => rowData.PlanoJuros || ''} />
          </DataTable>
          <div className='grid flex justify-content-center'>
            <div className='col-12 md:col-10 pt-4 flex justify-content-center align-items-center'></div>
            <div className='col-12 md:col-2 flex justify-content-end mt-2 '>
              <Button label='Executar' icon="pi pi-plus-o" className="p-button-success" type='button' style={{ width: '100%' }} onClick={onSubmit} />
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default AjustaDiarioFinanceiro;