import { useState, useEffect, useRef, SetStateAction } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { ALERTAS } from '../utilities/constantes';
import { useForm } from 'react-hook-form';
import { SCCalendar, SCCheckbox, SCDropdown, SCFieldset, SCInputNumber, SCRadioButton } from '../components';
import AjudaFormTela from './AjudaFormTela';
import { CheckboxChangeParams } from 'primereact/checkbox';
import Loading from '../components/Loading';
import { confirmDialog } from 'primereact/confirmdialog';
import { addMonths, format, setDate } from 'date-fns';


const FaturaCartao = () => {
  const toast = useToast();
  const { periodoSelecionado, usuario } = useAuth();
  const tarefa = 'FaturaCartao';

  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const [aPagar, setAPagar] = useState<any>([]);
  const [cartao, setCartao] = useState<any>([]);
  const [valorTotalSelecionado, setValorTotalSelecionado] = useState(0);
  const [loading, setLoading] = useState(false)
  const [disableQuitar, setDisableQuitar] = useState<boolean>(true)
  const [fonteVisivel, setFonteVisivel] = useState<boolean>(true)
  const [fontes, setFontes] = useState<any>(null);
  const [contas, setContas] = useState<any>(null);
  const [quitacaoParcial, setQuitacaoParcial] = useState<boolean>(false)
  // const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1720);

  const methods = useForm();
  const { control, watch, getValues, setValue } = methods;

  const watchCartaoInstitucional = watch('CartaoInstitucionalId')
  const watchDataI = watch('DataInicial')
  const watchDataF = watch('DataFinal')
  const watchFonte = watch('FonteId')
  const watchSendoPago = watch('ValorAPagar')
  const watchConta = watch('ContaValorId')

  const load = async () => {
    setLoading(true)
    setFonteVisivel(true)
    setValue('TipoPagamento', 1)
    const result = await api.get('/cartoes/listCartaoInstitucional', {
      params:
      {
        Institucional: 1,
        Tipo: 'D'
      }
    });

    if (result.data) {
      setCartao(result.data);
    } else {
      setCartao([])
    }

    const resultContas = await api.get('/planoContas/list', { params: { Tipo: 'SAÍDA' } });
    if (resultContas.data) {
      setContas(resultContas.data);
    } else {
      setContas([]);
    }

    const resultFontes = await api.get('/fontes/fonteAtivas', { params: { EmpresaId: periodoSelecionado?.EmpresaId } });
    if (resultFontes.data) {
      setFontes(resultFontes.data);
    } else {
      setFontes([]);
    }

    const formattedDataI = watchDataI ? new Date(watchDataI).toISOString().split('T')[0] : null;
    const formattedDataF = watchDataF ? new Date(watchDataF).toISOString().split('T')[0] : null;

    const result2 = await api.get('/apagar/listLiquidaFatura', {
      params: {
        DataI: formattedDataI,
        DataF: formattedDataF,
        CartaoInstitucionalId: watchCartaoInstitucional,
        FaturasQuitadas: false
      }
    })
    if (result2.data) {
      setAPagar(result2.data)
    } else {
      setAPagar([])
    }

    setLoading(false)
  };

  let FaturaID: any;

  useEffect(() => {
    load();
  }, []);

  const handleCheckboxChange = (rowData: any, checked: boolean) => {
    const updatedAPagar = aPagar.map((item: any) => {
      if (item.ApagarId === rowData.ApagarId) {
        return { ...item, Selecionado: checked ? 1 : 0 };
      }
      return item;
    });

    setAPagar(updatedAPagar);
    const algumSelecionado = updatedAPagar.some((item: any) => item.Selecionado === 1);
    setDisableQuitar(!algumSelecionado);
    setFonteVisivel(!algumSelecionado)
    atualizarValorTotalSelecionado(updatedAPagar);
  };

  const confirm = () => {
    if (valorTotalSelecionado > watchSendoPago && !watchConta) {
      toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Para pagamentos de valor menor que a fatura, é necessária seleção da conta.' });
      return
    } else {
      confirmDialog({
        message: (<div style={{ fontSize: '16px' }} className='col-12'>
          <div>
            Confirme se selecionou todos os lançamentos da fatura do cartão.
          </div>
          <div className='pt-2'>
            DESEJA PROSSEGUIR COM A QUITAÇÃO?
          </div>
        </div>),
        header: 'Atenção!',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        accept: watchSendoPago < valorTotalSelecionado ? valorMenor : onSubmit,
        style: { maxWidth: '600px', width: '80%' }
      });
    }
  };

  const valorMenor = () => {
    confirmDialog({
      message: (
        <>
          <div className='grid'>
            <div className='flex align-items-center'>
              <SCRadioButton
                control={control}
                name="TipoPagamento"
                label='Desconto'
                value={1}
              />
              <SCRadioButton
                control={control}
                name="TipoPagamento"
                label='Quitação Parcial'
                value={2}
                className='ml-3'
              />
            </div>
          </div>
        </>
      ),
      header: 'Tipo de Desconto',
      acceptLabel: 'Confirmar',
      rejectLabel: 'Cancelar',
      accept,
      style: { maxWidth: '300px', width: '30%' }
    });
  };

  const accept = async () => {
    const tipoSelecionado = getValues("TipoPagamento")

    // Para caso ele queira fazer uma quitação de fatura, com o valor menor, junto com a quitação parcial,
    // ele vai precisar quitar o pagamento parcial primeiro, e depois fazer o outro pagamento.
    if (quitacaoParcial) {
      toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Para quitação de de pagamento de fatura, não pode ser valor menor.' });
      return;
    }

    if (tipoSelecionado) {
      await onSubmit();

      const dataAtual = new Date();
      const novaData = setDate(addMonths(dataAtual, 1), 1);
      const dataFormatada = format(novaData, 'yyyy-MM-dd');

      switch (tipoSelecionado) {
        case 1:
          aPagar.forEach(async (item: any) => {
            if (item.Selecionado === 1) {
              let {
                ApagarId,
                AtualizacaoData,
                AtualizacaoHora,
                AtualizacaoOperador,
                CartoesId,
                CodigosEstornoId,
                ContaDescontoId,
                ContaJurosPagamento,
                ContaMultaPagamento,
                CredorId,
                CartaoInstitucionalId,
                CentroCustoId,
                ContaValorId,
                DataPagamentoString,
                DataProgramada,
                DataProgramadaString,
                DataVencimentoString,
                FaturaCartao,
                LancamentoData,
                LancamentoDataString,
                LancamentoHora,
                LancamentoOperador,
                LiquidaFaturaId,
                LiquidaValor,
                NomeCartao,
                NumeroDocumentoPagamento,
                Referente,
                Selecionado,
                ValorDescontoPagamento,
                ValorDescontoProgramado,
                ValorJurosPagamento,
                ValorJurosProgramado,
                ValorMultaPagamento,
                ValorMultaProgramado,
                ValorProgramado,
                ...newItemOutrosReceb
              } = item

              newItemOutrosReceb.Nome = 'Pagamento Parcial da Fatura ' + FaturaID
              newItemOutrosReceb.Valor = watchSendoPago
              newItemOutrosReceb.ValorPagamento = watchSendoPago
              newItemOutrosReceb.ExcluidoData = '0000-00-00'
              newItemOutrosReceb.ExcluidoHora = ''
              newItemOutrosReceb.ExcluidoOperador = ''
              newItemOutrosReceb.Matricula = 0

              newItemOutrosReceb.DataVencimento = dataFormatada;
              newItemOutrosReceb.DataPagamento = dataFormatada;
              newItemOutrosReceb.DataDisponivel = dataFormatada;
              newItemOutrosReceb.DataBomPara = dataFormatada;

              newItemOutrosReceb = {
                ...newItemOutrosReceb,
                Ano: periodoSelecionado?.Ano,
                EmpresaId: periodoSelecionado?.EmpresaId,
                OperadorLancamento: usuario?.Apelido,
                PagamentoSemValor: false,
                PlanoContaId: watchConta,
                Referencia: 'Pagamento Parcial da Fatura ' + FaturaID,
                Sequencial: periodoSelecionado?.Sequencial,
                ValorJuros: item.ValorJurosPagamento,
                ValorMulta: item.ValorMultaPagamento,
              }


              await api.post('/outrosRecebimentos', newItemOutrosReceb)
            }
          });

          break;
        case 2:
          const result = await api.get('/fontes/list', {
            params: {
              EmpresaId: periodoSelecionado?.EmpresaId,
              Tipo: 1
            }
          })
          const fonteID = result.data[0].FonteId

          const result2 = await api.get('/formasPagamento/localizaDinheiro')
          const tipoDinheiro = result2.data.FormasPagamentoId

          const idCredor = await api.post('/credor/createCredorFatura')

          aPagar.forEach(async (item: any) => {
            if (item.Selecionado === 1) {
              delete item.DataVencimentoString
              delete item.DataPagamentoString
              delete item.DataProgramadaString
              delete item.LancamentoDataString
              delete item.LiquidaValor
              delete item.Nome
              delete item.NomeCartao
              delete item.Selecionado

              let { ApagarId, NumeroDocumento, NumeroDocumentoPagamento,
                CredorId, CentroCustoId, ...newItemApagar } = item

              newItemApagar.Referente = 'Pagamento Parcial da Fatura ' + FaturaID;
              newItemApagar.FonteId = fonteID;
              newItemApagar.FormasPagamentoId = tipoDinheiro;
              newItemApagar.CredorId = idCredor.data.id;
              newItemApagar.ContaValorId = watchConta;
              newItemApagar.Valor = valorTotalSelecionado - watchSendoPago;
              newItemApagar.ValorPagamento = valorTotalSelecionado - watchSendoPago;
              newItemApagar.ValorProgramado = valorTotalSelecionado - watchSendoPago;
              newItemApagar.DataVencimento = dataFormatada;
              newItemApagar.DataPagamento = dataFormatada;
              newItemApagar.DataProgramada = dataFormatada;
              newItemApagar.DataBomPara = dataFormatada;
              newItemApagar.FaturaCartao = true;
              newItemApagar.LiquidaFaturaId = 0;
              newItemApagar.LancamentoData = dataAtual;
              newItemApagar.LancamentoHora = format(dataAtual, 'HH:mm');
              newItemApagar.LancamentoOperador = usuario?.Apelido;
              newItemApagar.AtualizacaoData = null;
              newItemApagar.AtualizacaoHora = null;
              newItemApagar.AtualizacaoOperador = null;
              console.log(newItemApagar)

              let apagarResponse = await api.post('/apagar', newItemApagar);

              if (apagarResponse?.data) {
                const idAPagar = apagarResponse.data.id;
                const ano = dataAtual.getFullYear().toString().slice(-2);
                const numSequencial = idAPagar.toString().padStart(7, '0');
                newItemApagar.NumeroDocumento = `${ano}/${numSequencial}`;
                newItemApagar.ApagarId = idAPagar;

                await api.put('/apagar', newItemApagar);
              }
            }
          });

          setTimeout(() => {
            // eslint-disable-next-line no-restricted-globals
            location.reload();
          }, 500);
          break;
      }
    } else {
      toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Selecione ao menos um tipo de pagamento.' });
    }
  }

  const onSubmit = async () => {
    try {

      if (watchSendoPago > valorTotalSelecionado) {
        toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Valores de quitação maiores que de fatura, não são permitidos.' });
        return;
      }

      let valorTotal = 0;
      const hours = String(new Date().getHours()).padStart(2, '0');
      const minutes = String(new Date().getMinutes()).padStart(2, '0');
      const formattedTime = `${hours}:${minutes}`;

      aPagar.forEach((item: any) => {
        if (item.Selecionado === 1) {
          valorTotal += item.Valor;
        }
      });

      const dados = {
        Valor: valorTotal,
        LancamentoData: new Date(),
        LancamentoHora: formattedTime,
        LancamentoOperador: usuario?.Apelido,
      }

      const novaFatura = await api.post('/liquidaFatura', dados);
      const idFatura = novaFatura.data[0];

      await Promise.all(
        aPagar.map(async (item: any) => {
          if (item.Selecionado === 1) {
            const updateApagar = {
              ApagarId: item.ApagarId,
              LiquidaFaturaId: idFatura,
              FonteId: watchFonte,
            }

            FaturaID = idFatura
            await api.put('/apagar/liquidaFatura', updateApagar);

            if (watchFonte) {
              const updateDiario = {
                NumeroRecibo: item.ApagarId,
                FonteId: watchFonte,
              }
              await api.put('/diariofinanceiro/updateFonte', updateDiario);
            }
          }
        })
      );

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      load()

      if (watchSendoPago < valorTotalSelecionado !== true) {
        setTimeout(() => {
          // eslint-disable-next-line no-restricted-globals
          location.reload();
        }, 500);
      }

    } catch (e: any) {
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }
  };

  const atualizarValorTotalSelecionado = (dados: any[]) => {
    const soma = dados
      .filter(item => item.Selecionado === 1)
      .reduce((acc, item) => acc + (item.Valor || 0), 0);
    setValorTotalSelecionado(soma);
    setValue('ValorAPagar', soma)
  };

  useEffect(() => {
    atualizarValorTotalSelecionado(aPagar);
  }, [aPagar]);

  // useEffect(() => {
  //   const handleResize = () => setIsLargeScreen(window.innerWidth >= 1720);
  //   window.addEventListener('resize', handleResize);
  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);

  useEffect(() => {
    if (aPagar.length > 0) {
      const updatedAPagar = aPagar.map((item: any) => {
        const isQuitacaoParcial = item.Referente?.includes("PAGAMENTO PARCIAL DA FATURA");
        if (isQuitacaoParcial && item.Selecionado !== 1) {
          setQuitacaoParcial(true)
          setFonteVisivel(false)
          setDisableQuitar(false)
          return { ...item, Selecionado: 1 };
        }
        return item;
      });

      if (JSON.stringify(updatedAPagar) !== JSON.stringify(aPagar)) {
        setAPagar(updatedAPagar);
      }
      atualizarValorTotalSelecionado(updatedAPagar);
    }
  }, [aPagar]);


  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Fatura de Cartão - Abertas</h5>
            <AjudaFormTela tarefa={tarefa} campo='' />
          </div>
          <SCFieldset legend='Filtros' className='col-12 mt-2 pt-0'>
            <div className='grid'>
              <div className="col-12 md:col-2">
                <SCCalendar
                  control={control}
                  label='Data Pagamento Inicial'
                  name='DataInicial'
                  dateFormat='dd/mm/yy'
                  mask='99/99/9999'
                  placeholder='Data Inicial'
                />
              </div>

              <div className="col-12 md:col-2">
                <SCCalendar
                  control={control}
                  label='Data Pagamento Final'
                  name='DataFinal'
                  dateFormat='dd/mm/yy'
                  mask='99/99/9999'
                  placeholder='Data Final'
                />
              </div>
              <div className="col-12 md:col-6">
                <SCDropdown
                  name="CartaoInstitucionalId"
                  label='Cartão Institucional'
                  options={cartao}
                  optionLabel='NomeCartao'
                  optionValue='CartoesId'
                  value={watchCartaoInstitucional}
                  onChange={(e) => setValue('CartaoInstitucionalId', e.target.value)}
                  style={{ width: '100%' }}
                />
              </div>
              <div className='col-12 md:col-2 flex align-items-end'>
                <Button label='Pesquisar' icon="pi pi-search" className="p-button-info p-button-outlined" type='button' onClick={load} />
              </div>
            </div>
          </SCFieldset>

          {loading ? <Loading /> : (
            <DataTable
              size='small'
              stripedRows
              value={aPagar}
              dataKey="id"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{first} a {last} de {totalRecords}"
              globalFilter={globalFilter}
              emptyMessage="Nenhum registro encontrado."
              header={null}
              responsiveLayout="scroll">
              <Column field="NumeroDocumento" header="Nº Documento" sortable headerStyle={{ width: '10%' }} ></Column>
              <Column field="DataPagamento" header="Data Pagamento" sortable body={(rowData: any) => {
                if (rowData.DataPagamento) {
                  return (
                    <span>{rowData.DataPagamentoString}</span>
                  );
                }
                return null;
              }} style={{ width: '16%' }} />
              <Column field="NomeCartao" header="Cartão Institucional" sortable headerStyle={{ width: '20%' }} ></Column>
              <Column field="Nome" header="Credor" sortable headerStyle={{ width: '20%' }} ></Column>
              <Column field="Referente" header="Referente" sortable headerStyle={{ width: '20%' }} ></Column>

              <Column field="Valor" header="Valor" align='center' sortable body={(rowData: any) => {
                return (
                  <span>R$ {rowData.Valor?.toFixed(2).replace('.', ',')}</span>
                );
              }} style={{ width: '15%' }}
              />

              <Column
                body={(rowData: any) => (
                  <div className="col-12 flex justify-content-center">
                    <SCCheckbox
                      checked={rowData.Selecionado === 1}
                      onChange={(e: CheckboxChangeParams) => handleCheckboxChange(rowData, e.checked)}
                      id={rowData.ApagarId}
                      name="Selecionado"
                    />
                  </div>
                )}
                alignHeader='center'
                align='center'
              />
            </DataTable>
          )}

          {fonteVisivel ? <div></div> : <SCFieldset legend='Dados de Quitação' className='col-12 mt-2 pt-0'>
            <div className='grid flex justify-content-center'>
              <div className='col-12 md:col-6 mt-3' style={{ textAlign: 'center', paddingRight: '10px', fontSize: '18px' }}>
                Total Selecionado: <strong>R$ {valorTotalSelecionado.toFixed(2).replace('.', ',')}</strong>
              </div>
            </div>
            <div className='grid flex justify-content-center'>
              <div className='col-12 md:col-4'>
                <SCInputNumber
                  control={control}
                  name="ValorAPagar"
                  label='Valor A Pagar'
                  style={{ width: '100%' }}
                  mode='decimal'
                  minFractionDigits={2}
                  maxFractionDigits={2}
                />
              </div>
              <div className="col-12 md:col-4">
                <SCDropdown
                  control={control}
                  name="ContaValorId"
                  label='Conta'
                  options={contas}
                  optionLabel='ContaDescricao'
                  optionValue='PlanoContaId'
                  value={watchConta}
                  onChange={(e) => setValue('ContaValorId', e.target.value)}
                />
              </div>
              <div className='col-12 md:col-4'>
                <SCDropdown
                  control={control}
                  name="FonteId"
                  label='Fonte'
                  options={fontes}
                  optionLabel='Codigo'
                  optionValue='FonteId'
                  value={watchFonte}
                  onChange={(e) => setValue('FonteId', e.target.value)}
                />
              </div>
            </div>
            <div className='grid flex justify-content-center'>
              <div className='col-12 md:col-10 pt-4 flex justify-content-center align-items-center'></div>
              <div className='col-12 md:col-2 flex justify-content-end mt-2 '>
                <Button label='Quitar Fatura' icon="pi pi-plus-o" className="p-button-success" type='button' style={{ width: '100%' }} onClick={confirm} disabled={disableQuitar} />
              </div>
            </div>
          </SCFieldset>}
        </div>
      </div>
    </div>

  );
};

export default FaturaCartao;