import { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useAuth } from '../providers/auth';
import { useToast } from '../context/ToastContext';
import { useForm } from 'react-hook-form';
import { SCCalendar, SCFieldset } from '../components';
import AjudaFormTela from './AjudaFormTela';
import { differenceInDays, eachDayOfInterval, format, parseISO, subDays } from 'date-fns';
import { CalculoMoviFin } from '../utilities/calculoMoviFin';
import Loading from '../components/Loading';
import api from '../services/api';

const EncerramentoMoviFin = () => {
  const toast = useToast();
  const { periodoSelecionado, usuario } = useAuth();
  const tarefa = 'EncerramentoMoviFin';

  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const [aPagar, setAPagar] = useState<any>([]);
  const [usuarios, setUsuarios] = useState<any>([]);
  const [valorTotalSelecionado, setValorTotalSelecionado] = useState(0);
  const [loading, setLoading] = useState(false)
  const [disableExecutar, setDisableExecutar] = useState<boolean>(true)

  const methods = useForm();
  const { control, watch, getValues, setValue } = methods;

  const watchUsuario = watch('UsuarioId')
  const watchDataI = watch('DataInicial')
  const watchDataF = watch('DataFinal')

  const criaMovimentacao = async (data: any, empresaId: number) => {
    try {
      // Formatar a data uma única vez para reutilização
      const dataFormatada = format(parseISO(data), 'yyyy-MM-dd');

      const { data: fontes } = await api.get('/fontes/fonteAtivas', {
        params: { EmpresaId: periodoSelecionado?.EmpresaId }
      });

      console.log('CONSULTA FONTE', fontes);

      // Usar Promise.all para processar todas as requisições em paralelo
      await Promise.all(fontes.map(async (f: any) => {
        try {
          const { data: movimentacaoData } = await api.get('/movimentacaoFonte/consultaMovimentacao', {
            params: {
              EmpresaId: empresaId,
              Data: dataFormatada,
              FonteId: f.FonteId
            }
          });

          // Criar objeto de dados uma única vez
          const dadosMovi = {
            EmpresaId: empresaId,
            MovimentacaoData: dataFormatada,
            FonteId: f.FonteId
          };

          console.log('Dados diários encontrados:', movimentacaoData);

          // Verificar se não existe movimentação e criar se necessário
          if (movimentacaoData.length === 0) {
            console.log('CRIANDO MOVIMENTANÇÃO', dataFormatada)
            await api.post('/movimentacaoFonte/criaMovimentacao', dadosMovi);
            console.log(`Movimentação criada para fonte ${f.FonteId}`);
          }
        } catch (error) {
          console.error(`Erro ao processar fonte ${f.FonteId}:`, error);
        }
      }));

      console.log('Processamento de movimentações concluído');
    } catch (error) {
      console.error('Erro ao criar movimentações:', error);
      throw error; // Propagar o erro para quem chamou a função
    }
  };

  const onSubmit = async () => {
    setLoading(true)
    setDisableExecutar(true)

    let dataInicial1 = new Date(watchDataI);
    let dataInicial = subDays(dataInicial1, 2);
    let dataFinal = new Date(watchDataF);

    // Buscando Parametro
    const limiteDias = await api.get('/parametros/getParametro', {
      params: {
        Grupo: 'F',
        EmpresaId: periodoSelecionado?.EmpresaId,
        Ano: periodoSelecionado?.Ano,
        Sequencial: periodoSelecionado?.Sequencial,
        Codigo: 'ENCMAX',
      }
    });

    if (limiteDias.data.Valor == 0) {
      setLoading(false)
      setDisableExecutar(false)

      toast.showToast({
        severity: 'error',
        summary: 'Atenção',
        detail: 'Informe o Parâmetro "Limite(dias) para Encerramento da Movimentação" ',
      });
      return;
    }

    // Verifica se o intervalo é maior que 30 dias
    const diferenca = differenceInDays(dataFinal, dataInicial);
    if (diferenca > (limiteDias.data.Valor + 2)) {
      setLoading(false)
      setDisableExecutar(false)

      toast.showToast({
        severity: 'error',
        summary: 'Atenção',
        detail: 'O intervalo não pode ser maior que ' + limiteDias.data.Valor + ' dia(s).',
      });
      return;
    }

    const datas = eachDayOfInterval({ start: dataInicial, end: dataFinal });

    // Criando Movimentação
    for (const data of datas) {
      const dataFormatada = format(data, 'yyyy-MM-dd');
      if (periodoSelecionado?.EmpresaId)
        await criaMovimentacao(dataFormatada, periodoSelecionado?.EmpresaId);
    }

    // Calculando a movimentação
    for (const data of datas) {
      // const dataFormatada = format(data, 'yyyy-MM-dd');
      await CalculoMoviFin('MOVFIN', null, periodoSelecionado?.EmpresaId, data, usuario?.Apelido);
    }

    toast.showToast({
      severity: 'success',
      summary: 'Sucesso!',
      detail: 'Movimentação encerrada com sucesso!',
    });

    setLoading(false)
    setDisableExecutar(false)

  };

  const prevValidation = useRef(true);

  useEffect(() => {
    const dataInicial = watchDataI;
    const dataFinal = watchDataF;

    if (!dataInicial || !dataFinal) {
      setDisableExecutar(true);
      return;
    }

    const isValid = dataInicial <= dataFinal;
    setDisableExecutar(!isValid);

    // Só mostra o toast se mudou de válido para inválido
    if (!isValid && prevValidation.current) {
      toast.showToast({
        severity: 'error',
        summary: 'Atenção',
        detail: 'Data inicial maior que a Data Final',
      });
    }

    prevValidation.current = isValid;
  }, [watchDataI, watchDataF]);

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Encerra Movimentação Financeira</h5>
            <AjudaFormTela tarefa={tarefa} campo='' />
          </div>

          {loading ? (
            <Loading />
          ) : (
            <SCFieldset legend='Período para o encerramento' className='col-12 mt-2 pt-0'>
              <div className='grid'>
                <div className='grid col-12 justify-content-center'>
                  <div className="col-12 sm:col-6 md:col-3 lg:col-2">
                    <SCCalendar
                      control={control}
                      label='Data Inicial'
                      name='DataInicial'
                      dateFormat='dd/mm/yy'
                      mask='99/99/9999'
                      placeholder='Data Inicial'
                    />
                  </div>

                  <div className="col-12 sm:col-6 md:col-3 lg:col-2">
                    <SCCalendar
                      control={control}
                      label='Data Final'
                      name='DataFinal'
                      dateFormat='dd/mm/yy'
                      mask='99/99/9999'
                      placeholder='Data Final'
                    />
                  </div>
                </div>
              </div>
            </SCFieldset>
          )}



          <SCFieldset legend='Observações' className='col-12 mt-2 pt-0'>
            <div className='grid justify-content-center gap-4'>
              <span style={{ color: 'orange' }}>O sistema recua para a <strong>ÚLTIMA DATA PROCESSADA</strong> anterior a data informada para reprocessar os saldos.</span>
              <span style={{ color: 'orange' }}>Mesmo quando não havendo movimentação na data, o sistema cria os saldos com base no último saldo calculado.</span>
            </div>
          </SCFieldset>

          <div className='grid flex justify-content-center'>
            <div className='col-12 md:col-10 pt-4 flex justify-content-center align-items-center'></div>
            <div className='col-12 md:col-2 flex justify-content-end mt-2 '>
              <Button label='Encerrar Movimentação' icon="pi pi-plus-o" className="p-button-success" type='button' style={{ width: '100%' }} onClick={onSubmit} disabled={disableExecutar} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );


};

export default EncerramentoMoviFin;