import { useState, useEffect, useRef, SetStateAction } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { ALERTAS } from '../utilities/constantes';
import { useForm } from 'react-hook-form';
import { SCCalendar, SCCheckbox, SCDropdown, SCFieldset } from '../components';
import AjudaFormTela from './AjudaFormTela';
import { CheckboxChangeParams } from 'primereact/checkbox';
import { InputMask } from 'primereact/inputmask';


const EntradaCartao = () => {
    const toast = useToast();
    const { periodoSelecionado, usuario } = useAuth();
    const tarefa = 'EntradaCartao';

    const [globalFilter, setGlobalFilter] = useState<any>(null);
    const [mensalidade, setMensalidade] = useState<any>([]);
    const [formasPagamento, setFormasPagamento] = useState<any>([]);

    const methods = useForm();
    const { control, watch, getValues, setValue } = methods;

    const watchFormasPagamento = watch('FormasPagamentoId')
    const watchLancDataI = watch('DataLancInicial')
    const watchLancDataF = watch('DataLancFinal')
    const watchCreditoDataI = watch('DataBomParaInicial')
    const watchCreditoDataF = watch('DataBomParaFinal')

    const load = async () => {
        const result = await api.get('/formasPagamento/list');
        if (result.data) {
            const filterCartao = result.data.filter((item: any) => { return item.Tipo == 3 })
            setFormasPagamento(filterCartao);
        } else {
            setFormasPagamento([])
        }

        const formattedLancDataI = watchLancDataI ? new Date(watchLancDataI).toISOString().split('T')[0] : null;
        const formattedLancDataF = watchLancDataF ? new Date(watchLancDataF).toISOString().split('T')[0] : null;

        const formattedCredDataI = watchCreditoDataI ? new Date(watchCreditoDataI).toISOString().split('T')[0] : null;
        const formattedCredDataF = watchCreditoDataF ? new Date(watchCreditoDataF).toISOString().split('T')[0] : null;

        const dataLancValida = (!formattedLancDataI && !formattedLancDataF) || (formattedLancDataI && formattedLancDataF);
        const dataPagValida = (!formattedCredDataI && !formattedCredDataF) || (formattedCredDataI && formattedCredDataF);

        if (!dataLancValida || !dataPagValida) {
            toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Preencha ambas as datas (Inicial e Final) para realizar o filtro.' });
            return;
        }

        const result2 = await api.get('/apagar/listEntradaCartao')
        if (result2.data) {
            const filteredData = result2.data.filter((item: any) => {
                const dataLancamento = item.LancamentoData
                    ? new Date(item.LancamentoData).toISOString().slice(0, 10)
                    : '';

                const DataBomPara = item.DataBomPara
                    ? new Date(item.DataBomPara).toISOString().slice(0, 10)
                    : '';

                const lancamentoDentroDoRange =
                    formattedLancDataI && formattedLancDataF
                        ? (dataLancamento && dataLancamento >= formattedLancDataI && dataLancamento <= formattedLancDataF)
                        : true;

                const pagamentoDentroDoRange =
                    formattedCredDataI && formattedCredDataF
                        ? (DataBomPara && DataBomPara >= formattedCredDataI && DataBomPara <= formattedCredDataF)
                        : true;

                const formaPagamentoDentroDoFiltro =
                    watchFormasPagamento
                        ? item.FormasPagamentoId === watchFormasPagamento
                        : true;

                return lancamentoDentroDoRange && pagamentoDentroDoRange && formaPagamentoDentroDoFiltro;
            });

            setMensalidade(filteredData)
        } else {
            setMensalidade([])
        }
    };

    useEffect(() => {
        load();
    }, []);

    const handleCheckboxChange = (rowData: any, checked: boolean) => {
        const updatedAPagar = mensalidade.map((item: any) => {
            if (item.ApagarId === rowData.ApagarId) {
                return { ...item, Selecionado: checked ? 1 : 0, editado: checked ? 1 : 0 };
            }
            return item;
        });

        setMensalidade(updatedAPagar);
    };

    const clickHandler = async () => {
        load()
    }

    const onSubmit = async () => {
        try {
            const data = mensalidade.filter((item: any) => item.Selecionado == 1)
            for (const item of data) {
                const DataProgramada = item.DataProgramadaString ? new Date(item.DataProgramadaString) : null;

                if (!DataProgramada) {
                    toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Data Pagamento não pode ser vazia!' });
                    break;
                }

                const novoArrayAPagar = {
                    ApagarId: item.ApagarId,
                    DataPagamento: new Date(item.DataProgramadaString),
                    ValorPagamento: item.ValorProgramado,
                    FormasPagamentoId: item.FormasPagamentoId,
                    FonteId: item.FonteId
                };

                await api.put('/apagar', novoArrayAPagar);
                await salvaDiarioFinanceiro(item);
            }
        } catch (e: any) {
            toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
        }
    };

    const salvaDiarioFinanceiro = async (data: any) => {
        const now = new Date();
        const formattedTime = `${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}`;

        let dadosDiarioFinanceiro = {
            EmpresaId: periodoSelecionado?.EmpresaId,
            Ano: periodoSelecionado?.Ano,
            Sequencial: periodoSelecionado?.Sequencial,
            LancamentoData: new Date(),
            LancamentoHora: formattedTime,
            LancamentoOperador: usuario?.Apelido,
            Tarefa: 'APAGAR',
            Opcao: 'INC',
            DataPagamento: new Date(data.DataProgramadaString),
            DataBomPara: new Date(data.DataProgramadaString),
            DataDisponivel: new Date(data.DataProgramadaString),
            Nome: data.Nome,
            Valor: data.ValorProgramado,
            Referencia: data.Referente,
            NumeroRecibo: data.ApagarId,
            FonteId: data.FonteId,
            FormasPagamentoId: data.FormasPagamentoId,
            ContaValorId: data.ContaValorId,
            CartaoNumero: data.NumeroDocumentoPagamento,
            FlgMeuCaixa: 0,
            Matricula: data.Matricula
        }

        try {
            await api.post('/diariofinanceiro', dadosDiarioFinanceiro)

            toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
            load()
        } catch (error) {
            console.error('Erro ao salvar no Diário Financeiro:', error);
            toast.showToast({
                severity: 'error',
                summary: 'Atenção',
                detail: 'Erro ao salvar o registro no Diário Financeiro',
            });
        }
    }

    const convertToDisplayFormat = (dateString: string): string => {
        const date = new Date(dateString);
        if (!isNaN(date.getTime())) {
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        }
        return '';
    };

    const DateInputEditor = ({ value, field, index, valorOriginal }: any) => {
        const [inputValue, setInputValue] = useState(convertToDisplayFormat(value));

        const handleBlur = () => {
            const editado: boolean = convertToDisplayFormat(valorOriginal) != inputValue
            handleDateChange(inputValue, field, index, editado)
        };

        return (
            <InputMask
                mask="99/99/9999"
                placeholder="DD/MM/YYYY"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onBlur={handleBlur}
                style={{ width: '120px' }}
            />
        );
    };

    const handleDateChange = (newDate: string, field: string, rowIndex: number, editado: boolean) => {
        setMensalidade((prevMensalidade: any) => {
            const updatedMensalidade = [...prevMensalidade];
            updatedMensalidade[rowIndex] = {
                ...updatedMensalidade[rowIndex],
                [field]: newDate,
                DataProgramadaString: convertToDisplayFormat(newDate),
                Selecionado: editado ? 1 : 0,
                editado: editado
            };
            return updatedMensalidade;
        });
    };

    const rowClass = (data: any) => {
        return {
            'bg-orange-500 text-black': data.editado,
        };
    };

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
                        <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Entrada De Cartao</h5>
                        <AjudaFormTela tarefa={tarefa} campo='' />
                    </div>
                    <SCFieldset legend='Filtros' className='col-12 mt-2 pt-0'>
                        <div className='grid'>
                            <div className="col-12 md:col-2">
                                <SCCalendar
                                    control={control}
                                    label='Data Disponível Inicial'
                                    name='DataBomParaInicial'
                                    dateFormat='dd/mm/yy'
                                    mask='99/99/9999'
                                    placeholder='Data Inicial'
                                />
                            </div>
                            <div className="col-12 md:col-2">
                                <SCCalendar
                                    control={control}
                                    label='Data Disponível Final'
                                    name='DataBomParaFinal'
                                    dateFormat='dd/mm/yy'
                                    mask='99/99/9999'
                                    placeholder='Data Final'
                                />
                            </div>
                            <div className="col-12 md:col-2">
                                <SCCalendar
                                    control={control}
                                    label='Data Lançamento Inicial'
                                    name='DataLancInicial'
                                    dateFormat='dd/mm/yy'
                                    mask='99/99/9999'
                                    placeholder='Data Inicial'
                                />
                            </div>
                            <div className="col-12 md:col-2">
                                <SCCalendar
                                    control={control}
                                    label='Data Lançamento Final'
                                    name='DataLancFinal'
                                    dateFormat='dd/mm/yy'
                                    mask='99/99/9999'
                                    placeholder='Data Final'
                                />
                            </div>
                            <div className="col-12 md:col-2">
                                <SCDropdown
                                    name="FormasPagamentoId"
                                    label='Formas de Pagamento'
                                    options={formasPagamento}
                                    optionLabel='Descricao'
                                    optionValue='FormasPagamentoId'
                                    value={watchFormasPagamento}
                                    onChange={(e) => setValue('FormasPagamentoId', e.target.value)}
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <div className='col-12 md:col-2 flex align-items-end'>
                                <Button label='Pesquisar' icon="pi pi-search" className="p-button-info p-button-outlined" type='button' onClick={clickHandler} />
                            </div>
                        </div>
                    </SCFieldset>
                    <DataTable
                        size='small'
                        stripedRows
                        value={mensalidade}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="{first} a {last} de {totalRecords}"
                        globalFilter={globalFilter}
                        emptyMessage="Nenhum registro encontrado."
                        header={null}
                        responsiveLayout="scroll"
                        rowClassName={rowClass}>
                        <Column field="NumeroDocumento" header="Nº Documento" align='center' />
                        <Column field="LancamentoDataString" header="Data Lançamento" align='center'
                            body={(rowData: any) => {
                                if (rowData.LancamentoData) {
                                    return (
                                        <span>{rowData.LancamentoDataString}</span>
                                    );
                                }
                                return null;
                            }}
                        />
                        <Column field="Nome" header="Nome" />
                        <Column field="Matricula" header="Matricula" align='center' />
                        <Column field="Descricao" header="Forma de Pagamento" align='center' />
                        <Column field="ValorProgramado" header="Valor Tarifa" align='center' body={(rowData: any) => {
                            return (
                                <span>R$ {rowData.ValorProgramado?.toFixed(2).replace('.', ',')}</span>
                            );
                        }} />
                        <Column field="DataProgramadaString" header="Data Credito" align='center'
                            body={(rowData: any) => convertToDisplayFormat(rowData.DataProgramadaString)}
                            editor={(props: any) =>
                                <DateInputEditor
                                    value={props.rowData.DataProgramadaString}
                                    field={'DataProgramada'}
                                    index={props.rowIndex}
                                    valorOriginal={props.rowData.DataProgramadaStringOriginal}
                                // onChange={(newValue: string) => handleDateChange(newValue, 'DataProgramada', props.rowIndex)}
                                />}
                        />
                        <Column
                            body={(rowData: any) => (
                                <div className="col-12 flex justify-content-center">
                                    <SCCheckbox
                                        checked={rowData.Selecionado === 1}
                                        onChange={(e: CheckboxChangeParams) => handleCheckboxChange(rowData, e.checked)}
                                        id={rowData.ApagarId}
                                        name="Selecionado"
                                    />
                                </div>
                            )}
                            alignHeader='center'
                            align='center'
                        />
                    </DataTable>
                    <div className='grid flex justify-content-center'>
                        <div className='col-12 md:col-10 pt-4 flex justify-content-center align-items-center'></div>
                        <div className='col-12 md:col-2 flex justify-content-end mt-2 '>
                            <Button label='Confirmar Entrada de Cartão' icon="pi pi-plus-o" className="p-button-success" type='button' style={{ width: '100%' }} onClick={onSubmit} />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default EntradaCartao;