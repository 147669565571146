import { format, parseISO } from "date-fns";
import api from "../services/api";
import { formatInTimeZone, toZonedTime } from 'date-fns-tz'

// Função para calcular a movimentação financeira
export const CalculoMoviFin = async (tipoCalculo: string, parametros?: object | null, empresaId?: number, data?: any, usuarioApelido?: String) => {

  console.log('DATA CALC', data)

  // Caso volte a dar erro de DATA, colocar o parseISO de volta nas datas (Data: format(parseISO(data), 'yyyy-MM-dd')).

  // Buscando Parametro
  const parEntradacartao = await api.get('/parametros/getParametro', {
    params: {
      Codigo: 'ENTCARAU',
      Grupo: 'F',
      EmpresaId: 0,
      Ano: '0000',
      Sequencial: '0'
    }
  });

  if (tipoCalculo === 'AJUSTA') {
    // Envia a atualização para a API
    await api.put('/movimentacaoFonte/saldoAjustado', parametros);
  }

  // Inicia os valores de movimentação da fonte
  await api.put('/movimentacaoFonte/iniciaValores', {
    EmpresaId: empresaId,
    Data: data
  });

  // Calculo Movimentação Financeira
  const { data: diarioData } = await api.get('/diariofinanceiro/listaMovimentacao', {
    params: {
      EmpresaId: empresaId,
      Data: data
    },
  });

  if (diarioData) {
    console.log(`DIARIO DATA`, diarioData)
    diarioData.forEach(async (item: any) => {

      let valor = item.Valor + item.ValorMulta + item.ValorJuros
        + item.ValorComplementar01 + item.ValorComplementar02 + item.ValorComplementar03
        + item.ValorComplementar04 + item.ValorComplementar05 + item.ValorComplementar06;

      const dataOriginal = item.DataDisponivel; // "2024-09-20T00:00:00.000Z"

      // Converter a data UTC para o fuso horário UTC (mantendo o valor original)
      const dataZonada = toZonedTime(new Date(dataOriginal), 'UTC');

      // Formatar a data
      const dataFormatada = format(dataZonada, 'yyyy-MM-dd');

      console.log(dataFormatada); // Deve exibir: 2024-09-20

      let dados = {
        EmpresaId: item.EmpresaId,
        MovimentacaoData: dataFormatada,
        FonteId: item.FonteId
      }

      console.log('DATA FOR', dados)

      if (['BAIAUT'].includes(item.Tarefa)) {
        let dadosParaAtualizar = { ...dados, Valor: valor, Campo: 'EntradaDinheiro' }
        await api.put('/movimentacaoFonte/atualizaMovimentacao', dadosParaAtualizar)
      }

      if (['TRFFON'].includes(item.Tarefa)) {
        if (item.Opcao === 'TRD') {
          let dadosParaAtualizar = { ...dados, Valor: valor, Campo: 'SaidaTransferencia' }
          await api.put('/movimentacaoFonte/atualizaMovimentacao', dadosParaAtualizar)
        }

        if (item.Opcao === 'TRC') {
          let dadosParaAtualizar = { ...dados, Valor: valor, Campo: 'EntradaTransferencia' }
          await api.put('/movimentacaoFonte/atualizaMovimentacao', dadosParaAtualizar)
        }
      }

      if (['OUTPAG', 'RECPAG', 'RECADI'].includes(item.Tarefa)) {
        if (item.Tipo === '1') {
          let dadosParaAtualizar = { ...dados, Valor: valor, Campo: 'EntradaDinheiro' }
          await api.put('/movimentacaoFonte/atualizaMovimentacao', dadosParaAtualizar)
        }

        if (item.Tipo === '2') {
          let dadosParaAtualizar = { ...dados, Valor: valor, Campo: 'EntradaCheque' }
          await api.put('/movimentacaoFonte/atualizaMovimentacao', dadosParaAtualizar)
        }

        if (item.Tipo === '3') {

          if (parEntradacartao.data.Valor === '1') {
            let dadosParaAtualizar = { ...dados, Valor: valor, Campo: 'EntradaCartao' }
            await api.put('/movimentacaoFonte/atualizaMovimentacao', dadosParaAtualizar)
          }
        }

        if (item.Tipo === '4') {
          let dadosParaAtualizar = { ...dados, Valor: valor, Campo: 'EntradaOutro' }
          await api.put('/movimentacaoFonte/atualizaMovimentacao', dadosParaAtualizar)
        }
      }

      if (['APAGAR'].includes(item.Tarefa)) {
        if (item.Tipo === '1') {
          let dadosParaAtualizar = { ...dados, Valor: valor, Campo: 'SaidaDinheiro' }
          await api.put('/movimentacaoFonte/atualizaMovimentacao', dadosParaAtualizar)
        }

        if (item.Tipo === '2') {
          let dadosParaAtualizar = { ...dados, Valor: valor, Campo: 'SaidaCheque' }
          await api.put('/movimentacaoFonte/atualizaMovimentacao', dadosParaAtualizar)
        }

        if (item.Tipo === '3') {
          if (parEntradacartao.data.Valor === '1') {
            let dadosParaAtualizar = { ...dados, Valor: valor, Campo: 'SaidaOutro' }
            await api.put('/movimentacaoFonte/atualizaMovimentacao', dadosParaAtualizar)
          }
        }

        if (item.Tipo === '4') {
          let dadosParaAtualizar = { ...dados, Valor: valor, Campo: 'SaidaDinheiro' }
          await api.put('/movimentacaoFonte/atualizaMovimentacao', dadosParaAtualizar)
        }
      }

    });
  }

  // Calcular Totais da Movimentação

  const { data: movimentacaoData } = await api.get('/movimentacaoFonte/listaMovimentacao', {
    params: {
      EmpresaId: empresaId,
      Data: data
    }
  });

  // Somando e enviando os valores
  await Promise.all(
    movimentacaoData.map(async (m: any) => {

      const dataOriginal = m.MovimentacaoData; // "2024-09-20T00:00:00.000Z"

      // Converter a data UTC para o fuso horário UTC (mantendo o valor original)
      const dataZonada = toZonedTime(new Date(dataOriginal), 'UTC');

      // Formatar a data
      const dataFormatada = format(dataZonada, 'yyyy-MM-dd');

      console.log(dataFormatada); // Deve exibir: 2024-09-20

      let dadosMovi = {
        EmpresaId: m.EmpresaId,
        Data: dataFormatada,
        FonteId: m.FonteId
      }

      let totalEntrada = m.EntradaDinheiro + m.EntradaCheque + m.EntradaCartao + m.EntradaTransferencia + m.EntradaOutro
      let totalSaida = m.SaidaDinheiro + m.SaidaCheque + m.SaidaTransferencia + m.SaidaOutro
      let totalSaldoAtualDinheiro = (m.SaldoAnteriorDinheiro + m.EntradaDinheiro + m.EntradaCartao + m.EntradaTransferencia + m.EntradaOutro) - (m.SaidaDinheiro + m.SaidaTransferencia + m.SaidaOutro)
      let totalSaldoAtualCheque = (m.SaldoAnteriorCheque + m.EntradaCheque) - (m.SaidaCheque)
      let totalSaldoAtual = totalSaldoAtualDinheiro + totalSaldoAtualCheque

      let encerramentoOpe = usuarioApelido
      let encerramentoFlag = true
      let encerramentoRef = 'AJUSTA SALDO FINAL DO DIA'

      let dadosEntradaAtualizar = { ...dadosMovi, Valor: totalEntrada, Campo: 'TOTALENTRADA' };
      await api.put('/movimentacaoFonte/atualizaValores', dadosEntradaAtualizar);

      let dadosSaidaAtualizar = { ...dadosMovi, Valor: totalSaida, Campo: 'TOTALSAIDA' };
      await api.put('/movimentacaoFonte/atualizaValores', dadosSaidaAtualizar);

      let dadosSaldoAtualDinheiro = { ...dadosMovi, Valor: totalSaldoAtualDinheiro, Campo: 'SALDOATUALDINHEIRO' };
      await api.put('/movimentacaoFonte/atualizaValores', dadosSaldoAtualDinheiro);

      let dadosSaldoAtualCheque = { ...dadosMovi, Valor: totalSaldoAtualCheque, Campo: 'SALDOATUALCHEQUE' };
      await api.put('/movimentacaoFonte/atualizaValores', dadosSaldoAtualCheque);

      let dadosSaldoAtual = { ...dadosMovi, Valor: totalSaldoAtual, Campo: 'SALDOATUALTOTAL' };
      await api.put('/movimentacaoFonte/atualizaValores', dadosSaldoAtual);

      let encerramentoFlg = { ...dadosMovi, Valor: encerramentoFlag, Campo: 'ENCERRAMENTOFLG' };
      await api.put('/movimentacaoFonte/atualizaValores', encerramentoFlg);

      let encerramentoOperador = { ...dadosMovi, Valor: encerramentoOpe, Campo: 'ENCERRAMENTOOPERADOR' };
      await api.put('/movimentacaoFonte/atualizaValores', encerramentoOperador);

      if (tipoCalculo === 'AJUSTA') {
        let encerramentoReferente = { ...dadosMovi, Valor: encerramentoRef, Campo: 'ENCERRAMENTOREFERENTE' };
        await api.put('/movimentacaoFonte/atualizaValores', encerramentoReferente);
      }

    })
  );
}